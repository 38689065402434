import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type componentState = {
    info: any,
    displayModal: boolean
};

const initialState = {
    info: [],
    displayModal: false,

} as componentState;

export const componentSlice = createSlice({
    name: "componentSlice",
    initialState,
    reducers: {

        setInfo: (state, action: PayloadAction<any>) => {
            state.info = action.payload
        },

        setDisplayModal: (state, action: PayloadAction<boolean>) => {
            state.displayModal = action.payload
        },
 
    },

});

export const {
    setInfo,
    setDisplayModal,
} = componentSlice.actions;
export default componentSlice.reducer;
