import React from 'react';
import 'styles/settings/fonts.css';
import 'styles/styles.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from 'pages/home';
import InfoModal from 'components/modal/info';
import { useLocation } from 'react-router-dom';

import 'animate.css';
import './i18n';
function App() {
    const { pathname } = useLocation();
    console.log(pathname);
    return (
        <>
            <HomePage />
            <InfoModal />
        </>
    );
}

export default App;
