import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface AnimatedProps {
    children: React.ReactNode;
    animation: string;
}
export default function Animated({ children, animation }: AnimatedProps) {
    const { ref, inView } = useInView({
        triggerOnce: true, // La animación se dispara solo una vez
        threshold: 0.1,
    });

    return (
        <div ref={ref} className={`animate__animated animated-item ${inView ? animation : ''}`}>
            {children}
        </div>
    );
}
