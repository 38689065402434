import { configureStore } from "@reduxjs/toolkit";
import componentSlice from "./slices/component.slice";
import { postApi } from "./services/post.services";


export const store = configureStore({
    reducer: {
        componentSlice,
        [postApi.reducerPath]: postApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat([
            postApi.middleware,
        ]),
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
