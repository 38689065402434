import React from 'react';
import { Modal } from 'antd';
import { setDisplayModal } from 'reduxStore/slices/component.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import { Link } from 'react-router-dom';
import { GrLinkNext } from 'react-icons/gr';

export default function InfoModal() {
    const dispatch = useAppDispatch();
    const displayModal = useAppSelector((state) => state.componentSlice.displayModal);
    const Info = useAppSelector((state) => state.componentSlice.info);

    return (
        <Modal
            width={992}
            title=""
            open={displayModal}
            centered
            footer={null}
            onCancel={() => {
                dispatch(setDisplayModal(false));
            }}
        >
            <main className="av__modal">
                <section className="av__modal__content">
                    {!Info.isResume ? (
                        <>
                            <h1>{Info.title}</h1>
                            <p>{Info.description}</p>
                        </>
                    ) : (
                        <>
                            <h2>{Info.title}</h2>
                            <p>{Info.paragraph1}</p>
                            <p>{Info.paragraph2}</p>
                            <p>{Info.paragraph3}</p>
                            <p>{Info.paragraph4}</p>
                            <p>{Info.paragraph5}</p>
                            <Link
                                to="https://www.californiaavocadogrowers.com/sites/default/files/documents/13-Health-Claim-for-Avocados-Approved-Spring-17.pdf"
                                target="_blank"
                                className="transition"
                            >
                                More information <GrLinkNext />
                            </Link>
                            <br />
                            <Link
                                to="https://hassavocadoboard.com/2016/12/19/fda-approves-heart-health-claim-for-fresh-hass-avocados/"
                                target="_blank"
                                className="transition"
                            >
                                More information <GrLinkNext />
                            </Link>
                        </>
                    )}
                </section>
            </main>
        </Modal>
    );
}
