import React, { useEffect } from 'react';
import { benefits, resume } from 'data/benefits';
import Animated from 'components/animation';
import { BsInfoCircleFill } from 'react-icons/bs';
import { TbHandClick } from 'react-icons/tb';
import { useAppSelector, useAppDispatch } from 'reduxStore/hooks';
import { setDisplayModal, setInfo } from 'reduxStore/slices/component.slice';

export default function HomePage() {
    const dispatch = useAppDispatch();

    return (
        <main className="av__main">
            <section className="av__map">
                <div className="av__map__main transition">
                    <Animated key={'main'} animation={`animate__jackInTheBox`}>
                        <div id="heart" className="heart__mobile"></div>
                        <h1
                            onClick={() => {
                                dispatch(setDisplayModal(true));
                                dispatch(setInfo(resume));
                            }}
                        >
                            <div id="heart" className="heart__web"></div>
                            Healthy <br />
                            Fats
                        </h1>
                        <div className="read-more">
                            <TbHandClick /> Click to read more
                        </div>
                    </Animated>
                </div>

                {benefits.map((benefit, index) => (
                    <>
                        <div
                            key={benefit.key}
                            className={`av__map__item av__map__item--${index + 1} transition`}
                            onClick={() => {
                                dispatch(setDisplayModal(true));
                                dispatch(setInfo(benefit));
                            }}
                        >
                            <Animated
                                key={benefit.key}
                                animation={`animate__jackInTheBox animate__delay-${index}s`}
                            >
                                <div className="number">
                                    <span>{index + 1}</span>
                                </div>
                                <h2>{benefit.title}</h2>
                                <div className="read-more">
                                    <TbHandClick /> Click to read more
                                </div>
                            </Animated>
                        </div>
                    </>
                ))}
            </section>
        </main>
    );
}
