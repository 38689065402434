import Paragraph from 'antd/es/skeleton/Paragraph';
import { title } from 'process';

export const benefits = [
    {
        key: 'benefit-1',
        title: (
            <>
                Reduce blood <br />
                pressure
            </>
        ),
        description:
            'Avocado is a natural source of potassium, a key mineral that plays an important role in regulating blood pressure. Eating foods rich in potassium, such as avocado, can help counteract the effects of sodium in the body, relaxing blood vessels and lowering blood pressure. Additionally, avocado contains healthy fats and antioxidants that contribute to improving overall cardiovascular health, making this fruit an excellent choice for those looking to maintain balanced blood pressure and a healthy heart.',
    },
    {
        key: 'benefit-2',
        title: (
            <>
                Reduce
                <br />
                cholesterol
            </>
        ),
        description:
            'Avocado is a food rich in healthy fats, particularly monounsaturated fatty acids, which have been shown to be effective in reducing LDL cholesterol levels (known as "bad cholesterol") in the blood. Various studies have indicated that regular consumption of avocado can help improve the lipid profile, decreasing total cholesterol and increasing HDL cholesterol levels ("good" cholesterol). In addition, its fiber and antioxidant content contributes to overall cardiovascular health, promoting better circulation and reducing the risk of heart disease.',
    },
    {
        key: 'benefit-3',
        title: (
            <>
                Control
                <br />
                heart rate
            </>
        ),
        description:
            'Avocado is an excellent source of potassium, an essential mineral for maintaining a healthy heart rhythm. Potassium helps regulate the electrical impulses that control the contraction and relaxation of the heart, thus contributing to maintaining a stable heart rate. In addition, avocado contains healthy fats that promote overall cardiovascular health, helping to reduce the risk of arrhythmias and other heart-related conditions. Including avocado in your diet can be a great ally in supporting a balanced heart rhythm and promoting heart health.',
    },
    {
        key: 'benefit-4',
        title: (
            <>
                Combat
                <br /> dehydration
            </>
        ),
        description:
            'Avocado is a food rich in water and essential minerals, such as potassium and magnesium, which play an important role in maintaining the bodys water balance. These minerals help regulate fluid levels in cells, combating dehydration and promoting proper hydration. In addition, avocado contains healthy fats that promote nutrient absorption, promoting more hydrated skin and tissues. Including avocado in your diet can be a great way to keep your body well hydrated, especially in hot weather or during intense physical activities',
    },
];

export const resume = {
    isResume: true,
    title: '“Avocados are good for you and their fat content is “good fat”',
    paragraph1:
        'In December, the FDA granted fresh Hass avocados the right to include a “heart-healthy” claim in advertising and on the product itself. This was the result of a petition from the American Heart Association (AHA), confirming that avocados contain healthy fats. Dr. Nikki Ford of the Hass Avocado Board (HAB) explained that the FDA issued an interim rule allowing avocados and other produce to use the health claim related to saturated fat and cholesterol, which aligns the definition of “healthy” with the differentiation between good and bad fats.',
    paragraph2:
        'Previously, any product with three or more grams of fat could not make this claim. Emiliano Escobedo, executive director of HAB, praised Ford for her work in supporting the AHA and noted that this will allow for stronger promotion of the health benefits of avocados. HAB will also target health professionals, who influence consumer habits, noting that avocados can now be included in diets that strictly follow FDA guidelines.',
    paragraph3:
        'In addition, HAB has developed avocado recipes that comply with the AHAs Health Verification Program, further reinforcing its role in a heart-healthy diet. HABs nutrition research program, established in 2010, will continue to explore the relationship between avocado consumption and cardiovascular health. Escobedo also encouraged other avocado advocacy organizations to take advantage of this new opportunity. Jan DeLyser of the California Avocado Commission noted that CAC will participate in the AHAs Health Verification Program for California Hass avocados sold in the U.S. and will help spread the word through media and social media.',
};
